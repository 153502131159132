import { Spinner } from "@fluentui/react";
import color from "common/styles/color";
import { styled } from "common/utils/stitches.config";
import * as React from "react";

interface Props {
  label: string;
  disabled?: boolean;
  type: "save" | "confirmation" | "error" | "outline";
  style?: React.CSSProperties;
  className?: any;
  onClick?: () => void;
  loading?: boolean;
}

export default function Button(props: Props) {
  const { label, disabled, type, style, className, onClick, loading } = props;

  return (
    <CustomButton
      type="submit"
      onClick={onClick}
      buttonType={type}
      disabled={disabled || loading}
      style={style}
      className={className}
    >
      <CustomLabel outline={type === "outline"}>{label}</CustomLabel>
      {!!loading && <Spinner style={{ marginLeft: 10 }} />}
    </CustomButton>
  );
}

const CustomButton = styled("button", {
  py: 10,
  borderWidth: 0,
  outline: "none",
  borderRadius: 12,
  width: "100%",
  "&:hover": {
    cursor: "pointer",
    opacity: 0.8,
  },
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  variants: {
    buttonType: {
      save: {
        backgroundColor: color.blue1,
      },
      confirmation: {
        backgroundColor: color.green2,
      },
      error: {
        backgroundColor: color.red1,
      },
      outline: {
        backgroundColor: color.white,
        border: `1px solid ${color.gray5}`,
      },
    },
    disabled: {
      true: {
        backgroundColor: color.gray4,
      },
    },
  },
});

const CustomLabel = styled("h4", {
  textTransform: "uppercase",
  color: color.white,
  variants: {
    outline: {
      true: {
        color: color.gray1,
      },
    },
  },
});
